
.navbar-container {
    color: white;
    text-align: center !important;
    background-color: #1B263B;
}

.nav-link {
    color: white;
    display: inline-block !important;
    font-weight: 900 !important;
}

.brand-and-links {
    padding-left: 50px;
    color: white;
    display: inline-block !important;
    vertical-align: middle !important;
}

.logo {
    object-fit: cover ;
    scale: 1.5;
    border-radius: 50% ;
    width: 150px;
    height: 150px;
    overflow: hidden !important;
} 

.nav-links {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}
.search-bar {
    margin-left: 40px;
    margin-right: 40px;
    justify-content: center;
} 
