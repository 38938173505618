
/* (A) FIXED WRAPPER */
.hwrap {
  overflow: hidden; /* HIDE SCROLL BAR */
  background: #778DA9;
  margin-bottom: 20px;
}
 
/* (B) MOVING TICKER WRAPPER */
.hmove { display: flex; }

/* (C) ITEMS - INTO A LONG HORIZONTAL ROW */
.hitem {
  white-space: nowrap;
  flex-shrink: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  text-align: center;
}
 
/* (D) ANIMATION - MOVE ITEMS FROM RIGHT TO LEFT */
@keyframes tickerh {
  0% { transform: translate3d(100%, 0, 0); }
  100% { transform: translate3d(-1100%, 0, 0); }
}
.hmove { animation: tickerh 200s  linear infinite; }
.hmove:hover { animation-play-state: paused; }


.text-crawl{
  color: black;
  font-size: large;
  font-weight: 500;
  text-decoration: none !important;
}
.text-crawl:hover {
  color:  white;
  text-decoration: underline !important;
}

@media screen and (max-width: 768px) {
  @keyframes tickerh {
    0% { transform: translate3d(100%, 0, 0); }
    100% { transform: translate3d(-3000%, 0, 0); } 
  }
}

